
body {
  font-family: 'Poppins' !important;
  /* height: 100%; */

}
html{
  position:relative;
}
.sidebar {
  width: 20%;
  background-color: #576971;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
}
.removesidebar {
  display: none;
}
/* #E03A3C */
.removesidebar {
  display: none;
}

.titledivcontainer {
  height: 17%;
  display: flex;
  align-items: center;
  padding-left: 5%;
  flex-direction: row;
  border-bottom: 0.01px rgba(255, 255, 255, 0.404) solid;
}

.titletop {
  font-size: 23px;
  font-weight: bold;
  color: white;
}
.titlebottom {
  font-size: 23px;
  font-weight: bold;
  color: white;
  margin-top: -5px;
}

.montylogo {
  width: 90px;
  height: 90px;
}
.titlediv {
  display: flex;
  flex-direction: column;
  margin-left: 3px;
}

#editor {
}

.navbareditor {
  width: 100%;
  padding-left: 20%;
}
.previewmaincontent {
  padding-left: 0% !important;
}

.tabs {
  height: 8%;
  display: flex;
  flex-direction: row;
}
.blocks {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  border-width: 0px;
  outline: none;
  border-right: 0.01px black;
  background-color: transparent;
  color: blue !important;
}

.tabsicon {
  color: white;
}

.blocks:hover {
  background-color: #9ea5a8;
}

.navbar {
  background-color: transparent;
  width: 100%;
  height: 8vh;
  background-color: #f7f7f7;
  z-index: 1;
}

.navtab {
  color: #576971 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.savebutton {
  background-color: #ff146b;
  color: white;
  font-size: 15px;
  cursor: pointer;
}

/* HOME PAGE CSS */

.homecontainer {
  height: 100vh;
  background-color: white;
  padding-top: 20px;
}
.topdiv {
  height: 30%;
  width: 100%;
  display: flex;
  padding-left: 50px;
  flex-direction: column;
  justify-content: center;
}
.hometitle {
  font-size: 25px;
  font-weight: bold;
  color: #000000;
}
.nameinput {
  width: 40%;
  height: 100%;
  padding-left: 10px;
  border-radius: 25px;
  border: 0.1px solid rgba(0, 0, 0, 0.426);
}
.nameinput:focus {
  outline: none;
  background-color: white !important;
}
.inputdiv {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  height: 26%;
}
.createpage {
  outline: none;
  border: 0;
  background-color: black;
  border-radius: 30px;
  color: #ffffff;
  font-size: 13px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
}
.tablediv {
  height: 70%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: red;
  align-items: center;
  justify-content: center;
}
.table {
  width: 50% !important;
  background-color: white;
}
/* Pages.... */

.maindiv {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  opacity: 1;
  padding-left:18%;
  background-color:#F1F4F9;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  /* animation:maindivani 0.5s; */

}
@keyframes maindivani {
  0%{
    padding-left:0%;
  }
  100%{
    padding-left:18%;
  }

}
.modaloverlay{
  width: 100vw;
  height: 100vh;
  background-color:red;
  display: none;
  top: 0;
  left: 0;
}
.homemodal {
  flex: 0.3;
  background-color: #1e2629;
  display: flex;
  flex-direction: column;
  height: 100%; /* 100% Full-height */
  width: 300px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  right: -300px;
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}
.createpagemodal{
  flex: 0.3;
  background-color: #ffffff;
  /* background: rgb(255,68,137);
  background: linear-gradient(90deg, rgba(255,68,137,1) 0%, rgba(255,45,122,1) 32%, rgba(255,20,107,1) 74%); */
  display: flex;
  flex-direction: column;
  height: 100%; /* 100% Full-height */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
  border-radius:10px;
  padding: 14px;

}
.pagecontainer {
  display: inline-flex;
  flex-wrap: wrap;
  flex: 1;
  background-color: transparent;
  height: 100vh;
  align-content: flex-start;
  width:90%;
  margin-top:20px;
}

.sidebarcontainer {
  flex: 0.7;
}
.pagediv {
  width: 18%;
  background-color: rgb(255, 255, 255);
  margin-right: 2%;
  margin-top: 15px;
  height: 250px;
  border-radius: 5px;
  /* background: url("https://i.pinimg.com/564x/da/3c/03/da3c03c3b2faa02626cc3e813c16169f.jpg"); */
  background-size: cover;
  animation: animatedBackground 0.5s ease-in;
  background-position: 0% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
  animation:animatepage 1s;
}
@keyframes animatepage{
  0%{
    opacity: 0;
  }
  100%{
    opacity:1;
  }
}

.pagebackground{
  height: 100%;
  width: 100%;
  background-Size: cover;

}
.pagebackground:hover{
  filter:brightness(0.5);
} 

/* .pagedetails:hover ~ .pagebackground { filter:brightness(0.5); } */


.pagedetails{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
}
.pagedetails:hover ~ .pagebackground{
  filter:brightness(0.5)
}

@keyframes animatedBackground {
  0% {
    background-size: cover;
    background-position: 100% 22%;
  }
  100% {
    background-size: cover;
    background-position: 0% 0%;
  }
}
.createpagediv {
  width: 18%;
  background-color: rgb(255, 255, 255);
  margin-right: 2%;
  margin-top: 15px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));

}
.loaderdiv{
  width: 18%;
  margin-right: 2%;
  margin-top: 15px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

}
.circlesdiv{
  display: flex;
  flex-direction: row;
  gap:10px;
}

.pagecircle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: transparent;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  animation: circleanimation 0.5s;
}
.pagecircle:hover {
  background-color: #ff146b;
  border: 0px solid #576971;
}

.createpagecircle {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  background-color: transparent;
  border: 2px solid #576971;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.createpagecircle:hover {
  /* width: 80px;
  height: 80px;
  border-radius: 40px; */
  background-color: #ff146b;
  border: 0px solid #576971;

}
.createpagecircle:hover .addicon{
  color: white;
}

@keyframes circleanimation {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 50px;
    height: 50px;
  }
}

.createpagetext {
  font-size: 16px;
  margin-top: 5px;
  font-weight: bold;
  color: #576971;
}
.editpagetext {
  font-size: 16px;
  margin-top: 5px;
  font-weight: bold;
  color: #ffffff;
}

.addicon {
  color: #576971;
}
.editicon {
  color: #ffffff;
}
/* Home-Sidebarr-modal css */

.sidebartitle {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight:200;
  margin-left: 10px;
}
.createpagesidebartitle{
  color: black;
  font-size: 20px;
  font-weight:200;
  margin-left: 10px;
}

.modaltitlediv {
  height: 10%;
  border-bottom: 0.1px solid rgba(255, 255, 255, 0.375);
  padding-left: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.pageicon {
  color:white;
}
.backtohomediv {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 10%;
  padding-left: 10px;
}

.backtext {
  font-size: 13px;
  color: white;
  margin-left: 5px;
}
.backicon {
  color: white;
}
.settingstext {
  font-size: 22px;
  color: white;
  font-weight: bold;
}
.settingsdiv {
  padding: 10px;
  height: 60%;
  display: flex;
  flex-direction: column;
}
.settingsnav {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.settingsicon {
  color: white;
}
.getpagetext {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.819);
  margin-top: 30px;
}
.copylinkdiv {
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linkinput {
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  outline: none;
  font-size: 15px;
  color: white;
}
.copyicon {
  color: white;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 2px;
}

.renametext {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.819);
  margin-top: 20px;
}
.renamediv {
  height: 15%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.renameinput {
  background-color: white;
  border-width: 0px;
  border-color: white;
  outline: none;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.799);
  padding-left: 10px;
}
.renamebutton {
  background-color: transparent;
  color: white;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 2px;
  border: 1px solid white;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
}
.renamebutton:hover {
  background-color: #ff146b;
  color: white;
  border: 1px solid #ff146b;

}
.actionstext {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.819);
  /* font-weight: bold; */
  margin-top: 20px;
}
.actionsdiv {
  height: 7%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap:10px;
}
.actionsbutton {
  background-color: white;
  color: #576971;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 20px;
  font-size: 15px;
  cursor: pointer;
  flex: 1;
  font-weight: bold;
}
.actionsbutton:hover {
  background-color: #ff146b;
  color: white;
  border: 1px solid #ff146b;
}
.sendbysmsdiv{
  height: 7%;
  margin-top: 10px;
}
.sendbysmsbutton{
  background-color: white;
  color: #576971;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 20px;
  font-size: 15px;
  cursor: pointer;
  font-weight: bold;
  width:100%;
  height:100%;
}
.sendbysmsbutton:hover{
  background-color: #ff146b;
  color: white;
  border: 1px solid #ff146b;
}

.templatestext {
  font-size: 18px;
  color: white;
  font-weight: bold;
}
.templatesdiv {
  padding: 10px;
  height: 80%;
  display: flex;
  flex-direction: column;
}
.templatescontainer {
  flex:1;
  height: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: flex-start;
  margin-right: 20px;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
  padding-left: 100px;
  padding-right: 100px;


}


@keyframes position {
  0%{
    background-position:0px 0px;
  }
  50%{
    background-position:0px -200px;
  }
  100%{
    background-position:0px 0px;
  }
}
.templaterow2{
  
  width: 31.33%;
  margin-right: 2%;
  background-color: rgb(255, 255, 255);
  margin-top: 2%;
  height: 49%;
  border-radius: 10px;
  padding:10px;

  /* background-size: cover;
  animation: animatedBackground 0.5s ease-in;
  background-position: 0% 0%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
/* animation:position 4s ease-in-out; */
}





.displayicon{
  color:#ff146b
}


.closeicon {
  color: white;
  position: absolute;
  right: 10px;
  cursor: pointer;
}
/* Create Page css */
.homesidebar {
  width: 18% !important;
  height: 100vh;
  position: fixed !important;
  overflow-y: scroll;
  min-width: unset !important;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
  background-color: white ; 
}
.sidebarcontent{
  height: 50%;
padding: 5%;

}
.sidebarheader{
  height: 20%;
  border-bottom: 0.1px solid rgb(132, 130, 130,0.3);
}
.homesidebartitlediv{
  display: flex;
  justify-content: center;
  align-items: center;
  height:100%;
  flex-direction:column
}
.homesidebartitletop{
  font-size:36px;
  font-weight: bold;
  color: #ff146b;
}
.homesidebartitlebottom{
  font-size:14px;
  font-weight: bold;
  color: #ff146b;
  margin-top: -13px;
  letter-spacing: 2.7px;

}
.sidebartab{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 15%;
  padding-left:10%;
  border-radius:10px
}
.sidebartab:hover{
  background: rgb(255,68,137);
  background: linear-gradient(90deg, rgba(255,68,137,1) 0%, rgba(255,45,122,1) 32%, rgba(255,20,107,1) 74%);  color:white ;
  box-shadow: 0 0 10px 1px rgb(255,68,137,0.7);

}
.sidebartab:hover  .sidebartabbutton{
  color: white;

}
.sidebartab:hover .sidebartabicon{
  color: white;

}

.sidebartabicon{
  color: gray;
}
.sidebartabbutton{
  color: gray;
  background-color: transparent;
  border:0;
  margin-left: 2px;
}
.pro-sidebar-inner{
  background-color: white !important; 
  color:black !important;

}
.createpagecontainer{
  display: flex;
  flex-direction: row;
  width: 90%;
  flex:1;
  margin-top: 20px;
}

/* Create PAge Modal css */
.modaltemplatediv{  
  height: 5%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  color: #ff146b;

}
.modaltemplatetitle{
  font-weight: bold;
  font-size: 15px;
}
.inputnamediv{
  height: 15%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
}
.pagenameinput{
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  outline: none;
  font-size: 15px;
  color: rgb(0, 0, 0);
}

.pageheaddiv{
  height:7vh;
  background-color:white;
  width:90%;
  border-radius:5px ;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left:10px;

}
.pageheadspan{
  margin-left: 7px;
  font-size: 13px;
  margin-top: 2px;
}

.useravatar{
  height:30px;
  width:30px;
  border-radius:15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border:0.1px solid rgba(0, 0, 0, 0.168)
}
.usericon{
  height:15px;
  width:15px;
}

/* TEMPLATES CSS */

.template{
  width: 31.33%;
  margin-right: 2%;
  background-color: rgb(255, 255, 255);
  height: 300px;
  border-radius: 10px;
  padding:10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
  -webkit-transition: background-position 2s ease-in-out;
  -moz-transition: background-position 2s ease-in-out;
  -ms-transition: background-position 2s ease-in-out;
  -o-transition: background-position 2s ease-in-out;
  transition: background-position 2s ease-in-out;
  animation:templatein 0.2s ease-in-out
}
@keyframes templatein {
  0%{
    opacity: 0;
    transform:scale(0,0);
  }
  100%{
    opacity: 1;
    transform:scale(1,1);
  }
  
}
.templatemargin{
  width: 31.33%;
  margin-right: 2%;
  background-color: rgb(255, 255, 255);
  height: 300px;
  border-radius: 10px;
  padding:10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  filter: drop-shadow(1px 1px 5px rgba(0, 0, 0, 0.227));
  -webkit-transition: background-position 2s ease-in-out;
  -moz-transition: background-position 2s ease-in-out;
  -ms-transition: background-position 2s ease-in-out;
  -o-transition: background-position 2s ease-in-out;
  transition: background-position 2s ease-in-out;
  margin-top: 20px;
}
.templatemargin:hover{
  background-position:0px -200px;

}
.template:hover{
  background-position:0px -200px;

}
.templateinnerdiv{
  background-color:rgb(255, 255, 255);
  position:absolute;
  width:100%;
  bottom:0;
  height:15%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  filter: drop-shadow(1px -5px 5px rgba(0, 0, 0, 0.227));
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: height 0.5s ;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

}

.arrowup{
  filter: drop-shadow(1px 3px 5px rgba(0, 0, 0));
  color: white;
  cursor: pointer;
  margin-right: 10px;
}
.arrowdiv{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top:-15px;
}

.templateinput{
  background-color: transparent;
  border-width: 0px 0px 1px 0px;
  outline: none;
  font-size: 15px;
  color: rgb(0, 0, 0);
  margin-top: 20px;
  width:90%;
}
.templatename{
  font-weight: bold;
  color: gray;

}

.templateinputdiv{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.createpagebutton{
  background: rgb(255,68,137);
  background: linear-gradient(90deg, rgba(255,68,137,1) 0%, rgba(255,45,122,1) 32%, rgba(255,20,107,1) 74%);  color:white ;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  width:30%;
  flex:1;
}
.templatebuttons{
  margin-top: 20px;
  display: flex;
  flex-direction:row;
  align-items: center;
  width: 100%;
  height:18%;
  padding-right:20px;

}
.displaybutton{
  background-color: transparent;
  flex: 0.4;
  border:0.2px solid #ff146b;
  border-radius: 20px;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

}
.upshowndiv{
  height:100%;
  margin-top: 20px;

}
.upshowndiv{
  animation:show 3s;
}
@keyframes show{
  0%{
    opacity: 0;
  }
  100%{
    opacity:1 ;
  }
}
.initialtitlediv{
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:-5px;
}




/*Login css  */
.loginpage{
  height:100vh;
  background-color:white;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
}
.logincontainer{
  width:25%;
  height:60%;
  background-color:transparent;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:20px;
  margin-bottom: 70px;
}
.usernameinput{
  background-color:#E8F0FE;
  border-radius:20px;
  padding:10px;
  color:black;
  border: 0;
  font-size: 13px;
  width:80%;
}
.usernameinput:focus{
  outline: none;
}
.usernameinput::placeholder{
  color: rgb(88, 88, 88);
}
.passworddiv{
  background-color:#E8F0FE;
  width:80%;
  background-color:#E8F0FE;
  border-radius:20px;
  align-items: center;
  display: flex;
}
.passwordinput{
  background-color:transparent !important;
  padding:10px;
  color: black;
  border: 0;
  width:90%;
  font-size: 13px;
  border-radius:20px;
  font-family: unset !important;
}

input {
  text-shadow: none !important;
}
.passwordinput:focus{
  outline: none;
}
.passwordinput::placeholder{
  color: rgb(88, 88, 88);
}
.signintext{
  font-size:30px;
  margin-top: -30px;
}
.loginsubtitle{
  font-weight:50;
  font-size:12px;
  word-spacing: 2px;
  color: rgb(109, 109, 109);

}
.errordiv{
  width: 77%;
}
.loginerrordiv{
  width: 80%;
  display: flex;
  flex-direction: column;
}
.errortext{
  color: red;
  font-size: 13px;
  margin-top: 10px;
  margin-left: 5px;
}
.templateerrortext{
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
.loginbutton{
  width:100%;
  border:0;
  background: rgb(255,20,107);
  background: linear-gradient(90deg, rgba(255,20,107,1) 0%, rgba(255,9,51,1) 100%);
   padding:10px;
  border-radius:20px;
  color:white;
  /* font-size: 13px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  

}
.MuiCircularProgress-root{
  color: white !important;
  width:20px !important;
  height:20px !important;
  position:absolute !important;
  right:20px;
}
.loginlogo{
  width:100px;
  height:100px;
}
.sidebarlogoutdiv{
  position:absolute;
  bottom: 0;
  height:10vh;
  width:100%;
  display: flex;
  justify-content:center;
  align-items:center;
  background-color:transparent;
  border-top: 0.1px solid rgb(132, 130, 130,0.3);

}
.logoutbutton{
  background: rgb(255,20,107);
  background: linear-gradient(90deg, rgba(255,20,107,1) 0%, rgba(255,9,51,1) 100%);
  color: white;
  width: 30%;
  border: 0;
  border-radius:20px;
  padding:6px;
  font-size: 12px;


}


/* Snackbar Toast*/
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #28A745; /* Black background color */
  border: 1px solid rgb(141, 255, 141);
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 5px; /* Rounded borders */
  padding: 10px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  right: 5%; /* Center the snackbar */
  bottom: 20px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
#redsnackbar {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #a72828; /* Black background color */
  border: 1px solid rgb(255, 141, 141);
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 5px; /* Rounded borders */
  padding: 10px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  right: 5%; /* Center the snackbar */
  bottom: 20px; /* 30px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#redsnackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 20px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 20px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 20px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 20px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.pageloader{
  color:black !important;
  right:unset !important;
  position: unset !important;
  height:40px !important;
  width:40px !important;

}
.gjs-block svg{
  height:45px;
}
#gjs-sm-transform-scale-z{
  display: none;
}


/* Create Page Loader */
.modal-background{
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;

}

.modal-loader {
  
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
  width:13%;
  animation:animateloader 0.2s ease-in-out;

}
@keyframes animateloader {
  0%{
      transform: scaleX(0);
  }
  100%{
      transform: scaleX(100%);
  }
  
}

.createpage-loader{
  width:25px;
  height:25px;
  border: 2px solid white;
  display:block;
  border-top: 2px solid black; 
  border-radius: 50%;
  animation: spin 1s linear infinite;

}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}






/* Save to Database Loader */
.savedbmodal-background{
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent black background */
  display: none;
  justify-content: center;
  align-items: center;
  transform-origin: center;

}
.savedbmodal-loader {
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fefefe;
  width:18%;
  animation:animateloader 0.2s ease-in-out;
  

}


.savedb-spin{
  width:25px;
  height:25px;
  border: 2px solid white;
  display:none;
  border-top: 2px solid black; 
  border-radius: 50%;
  animation: spin 1s linear infinite;

}
.savedbsuccess{
  color:#28A745;
  display:none;
  text-align: center;
  font-size: 13px;
}
.savedbfail{
  color:red;
  display:none;
  text-align: center;
  font-size: 13px;



}
.disablespan { 
  pointer-events: none; 
}
.btn-save::before{
  content:"Save"
} 
 .btn-save{
    background-color: #ff146b;
    color: white;
    font-size: 15px;
    cursor: pointer;
}